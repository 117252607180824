var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-modal" }, [
    _c(
      "div",
      { staticClass: "selection-holder flex flex--column column-center" },
      [_vm._t("content")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }