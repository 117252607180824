








import { Vue,  Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseButton extends Vue {
    @Prop({ type: String, required: false, default: 'base-btn' }) public buttonClass!: string;

    @Prop({ type: Boolean, required: false, default: false }) public disabled: boolean;

    @Prop({ type: [String, Number], required: false, default: '' }) public tooltipText!: string | number;

    public onClick (): void {
        this.$emit('click');
    }
}
