var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltipText ? _vm.tooltipText : "",
          expression: "tooltipText ? tooltipText : ''",
        },
      ],
      class: _vm.buttonClass,
      attrs: { type: "button", disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }